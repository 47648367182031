/*
 * @Author: duanyunlong
 * @Date: 2020-07-30 11:16:01
 * @LastEditTime: 2020-08-01 09:28:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dc_container\src\config\request.js
 */
/**
 * 请求参数配置
 */
const REQUEST_KEY = {
  requestParams: {
    // vue代理前缀
    prefix: process.env.NODE_ENV === 'development' ? 'api' : 'api',
    // 跨域是否需要凭借
    withCredentials: false,
    //  请求超时时间
    timeout: 6000000
  },
  requestMethod: {
    get: 'get',
    post: 'post',
    put: 'put',
    delete: 'delete'
  },
  requestMode: {
    json: 0,
    formData: 1,
    urlencoded: 2
  },
  responseType: {
    json: 'json',
    text: 'text',
    blob: 'blob'
  },
  requestConfig: {
    // 是否开启遮盖
    isCover: false,
    // 后台返回给前端请求成功标志code
    code: '0',
    // 返回的数据存储位置
    data: 'data',
    // 通过哪个标识为判断依据
    codeText: 'code',
    // 是否自行处理错误
    isError: false
  },
  requestExpandConfig: {
    // 请求超时code
    timeOutCode: '9',
    // 无权限code
    noLimitsCode: '1007'
  },
  serviceConfig: {
    // ip及端口
    host: window.location.host,
    // 协议
    protocol: window.location.protocol,
    // 系统首页
    homeUrl: '/index',
    // 系统登录页
    loginUrl: '/login',
    // 系统无权限页面
    noPermissionUrl: '/401',
    // 系统布局页
    layoutUrl: '/layout'
  },
  webSocketConfig: {
    protocol: 'ws://',
    suffix: '/webSocket'
  },
  // 文件相关配置
  fileConfig: {
    // 默认文件前缀
    uploadPrefix: '/upload/',
    // 临时文件前缀
    tempPrefix: '/temp/',
    // 备份文件前缀
    backupPrefix: '/backup/',
    // 模板下载前缀
    templatePrefix: ''
  }
}
export default REQUEST_KEY
