/**
 * 医院管理
 */
const HOSPITAL = [{
  // 医院管理
  path: '/hospital',
  name: 'Hospital',
  component: () => import('@/views/inquiry/hospitalManage/hospital/hospital'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '医院管理',
      path: '/hospital'
    }]
  }
}, {
  // 科室管理
  path: '/hospital-departments',
  name: 'Departments',
  component: () => import('@/views/inquiry/hospitalManage/departments/departments'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '科室管理',
      path: '/hospital-departments'
    }]
  }
}, {
  // 医陪认证管理
  path: '/accompany',
  name: 'Accompany',
  component: () => import('@/views/inquiry/accompanyManage/accompany/accompany'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '医陪认证管理',
      path: '/accompany'
    }]
  }
}, {
  // 医陪广告位
  path: '/recommend',
  name: 'Recommend',
  component: () => import('@/views/inquiry/accompanyManage/recommend/recommend'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '医陪广告位管理',
      path: '/recommend'
    }]
  }
}, {
  // 医陪画像海报
  path: '/poster',
  name: 'Poster',
  component: () => import('@/views/inquiry/accompanyManage/poster/poster.vue'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '医陪画像海报',
      path: '/poster'
    }]
  }
}, {
  // 医生管理
  path: '/hospital-doctor',
  name: 'Doctor',
  component: () => import('@/views/inquiry/doctorManage/doctor/doctor'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '医生管理',
      path: '/hospital-doctor'
    }]
  }
}, {
  // 医生价格管理
  path: '/price',
  name: 'Price',
  component: () => import('@/views/inquiry/doctorManage/price/price.vue'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '医生价格管理',
      path: '/price'
    }]
  }
}, {
  // 医生广告位管理
  path: '/hospital-advertise',
  name: 'Advertise',
  component: () => import('@/views/inquiry/doctorManage/advertise/advertise'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '医生广告位管理',
      path: '/hospital-advertise'
    }]
  }
}, {
  // 医院广告位管理
  path: '/hospital-notice',
  name: 'Notice ',
  component: () => import('@/views/inquiry/hospitalManage/notice/notice'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '医院广告位管理',
      path: '/hospital-notice'
    }]
  }
}, {
  // 客服中心管理
  path: '/service',
  name: 'Serveice ',
  component: () => import('@/views/inquiry/other/service/service'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '客服中心管理',
      path: '/serveice'
    }]
  }
}, {
  // 评论管理
  path: '/evaluate',
  name: 'Evaluate',
  component: () => import('@/views/inquiry/other/evaluate/evaluate'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '评论管理',
      path: '/evaluate'
    }]
  }
}, { // 订单相关
  // 医陪订单管理
  path: '/order-accompany',
  name: 'AccompanyOrder',
  component: () => import('@/views/inquiry/orderManage/accompany-order/accompany'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '医陪订单管理',
      path: '/order-accompany'
    }]
  }
}, {
  // 问诊订单管理
  path: '/order-inquiry',
  name: 'InquiryOrder',
  component: () => import('@/views/inquiry/orderManage/inquiry-order/inquiry'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '问诊订单管理',
      path: '/order-inquiry'
    }]
  }
}, {
  // 挂号订单管理
  path: '/order-register',
  name: 'RegisterOrder',
  component: () => import('@/views/inquiry/orderManage/register-order/register'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '挂号订单管理',
      path: '/order-register'
    }]
  }
}, {
  path: '/blotter',
  name: 'Blotter',
  component: () => import('@/views/inquiry/orderManage/blotter/blotter'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '订单流水管理',
      path: '/blotter'
    }]
  }
}]
export default HOSPITAL
