/**
 * 社区管理
 */
const COMMUNITY = [{
  // 审核
  path: '/audit',
  name: 'Audit',
  component: () => import('@/views/community/community-video/audit/audit'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '审核管理',
      path: '/audit'
    }]
  }
}, {
  // 标签
  path: '/label',
  name: 'Label',
  component: () => import('@/views/community/community-video/label/label'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '标签管理',
      path: '/label'
    }]
  }
}, {
  // 分类
  path: '/classification',
  name: 'Classification',
  component: () => import('@/views/community/community-video/classification/classification'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '分类管理',
      path: '/classification'
    }]
  }
}, {
  // 视频
  path: '/video',
  name: 'Video',
  component: () => import('@/views/community/community-video/video/video'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '视频管理',
      path: '/video'
    }]
  }
}, {
  // 评论
  path: '/comment',
  name: 'Comment',
  component: () => import('@/views/community/community-video/comment/comment'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '评论管理',
      path: '/comment'
    }]
  }
}, {
  // 回复
  path: '/reply',
  name: 'Reply',
  component: () => import('@/views/community/community-video/reply/reply'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '回复管理',
      path: '/reply'
    }]
  }
}, {
  // 文章管理
  path: '/essayManage',
  name: 'EssayManage',
  component: () => import('@/views/community/community-circle/essayManage/essayManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '文章管理',
      path: '/essayManage'
    }]
  }
}, {
  // 文章问答
  path: '/issue',
  name: 'Issue',
  component: () => import('@/views/community/community-circle/issue/issue'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '文章问答',
      path: '/issue'
    }]
  }
}, {
  // 圈子
  path: '/CircleAssoci',
  name: 'CircleAssoci',
  component: () => import('@/views/community/community-circle/CircleAssoci/CircleAssoci'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '圈子管理',
      path: '/CircleAssoci'
    }]
  }
}, {
  path: '/famous-doctor',
  name: 'FamousDoctor',
  component: () => import('@/views/community/famous-doctor/doctor/doctor'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '名医管理',
      path: '/famous-doctor'
    }]
  }
}, {
  path: '/famous-hospital',
  name: 'FamousHospital',
  component: () => import('@/views/community/famous-hospital/hospital/famousHospital'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '名院录',
      path: '/famous-hospital'
    }]
  }
}, {
  path: '/famous-lead',
  name: 'FamousLead',
  component: () => import('@/views/community/famous-hospital/lead/lead'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '领导团队管理',
      path: '/famous-lead'
    }]
  }
}, {
  path: '/famous-department',
  name: 'FamousDepartment',
  component: () => import('@/views/community/famous-hospital/department/department'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '名院科室管理',
      path: '/famous-department'
    }]
  }
}, {
  path: '/hospital-famous-doctor',
  name: 'HospitalFamousDoctor',
  component: () => import('@/views/community/famous-hospital/doctor/doctor'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '名院名医推荐',
      path: '/hospital-famous-doctor'
    }]
  }
}, {
  path: '/doctor-recommend',
  name: 'DoctorRecommend',
  component: () => import('@/views/community/formation/doctor/doctor-recommend'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '名医推荐',
      path: '/doctor-recommend'
    }]
  }
}, {
  path: '/user-recommend',
  name: 'UserRecommend',
  component: () => import('@/views/community/formation/users/user-recommend'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '用户推荐',
      path: '/user-recommend'
    }]
  }
}, {
  path: '/mcn-recommend',
  name: 'MCNRecommend',
  component: () => import('@/views/community/formation/mcn/mcn-recommend'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '抖医mcn收集信息',
      path: '/mcn-recommend'
    }]
  }
}, {
  path: '/Carouse',
  name: 'Carouse',
  component: () => import('@/views/community/community-carouse/carouse'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '轮播图管理',
      path: '/carouse'
    }]
  }
}, {
  path: '/DoctorVideo',
  name: 'DoctorVideo',
  component: () => import('@/views/community/community-doctorVideo/doctorVideo'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '推荐视频管理',
      path: '/doctorVideo'
    }]
  }
}, {
  path: '/DoctorCarouselTv',
  name: 'DoctorCarouselTv',
  component: () => import('@/views/community/community-doctorCarouselTv/doctorCarouselTv'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '直播回放管理',
      path: '/doctorCarouselTv'
    }]
  }
}, {
  path: '/DoctorDirectories',
  name: 'DoctorDirectories',
  component: () => import('@/views/community/community-doctorDirectories/doctorDirectories'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '账户绑定',
      path: '/doctorDirectories'
    }]
  }
}, {
  path: '/BroadcastManage',
  name: 'BroadcastManage',
  component: () => import('@/views/community/community-broadcastManage/broadcastManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '直播分类管理',
      path: '/broadcastManage'
    }]
  }
}, {
  path: '/APPlivebroadcast',
  name: 'APPlivebroadcast',
  component: () => import('@/views/community/community-APPlivebroadcast/APPlivebroadcast'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '直播分类管理',
      path: '/APPlivebroadcast'
    }]
  }
}
]
export default COMMUNITY
